.c-leaders {
  margin-bottom: 50px;
}

.c-providers__entry,
.c-leaders__entry {
  padding-bottom: 30px;
}

.c-providers__location-name {
  margin-bottom: 20px;
  text-transform: uppercase;
}

.c-provider {
  text-align: center;
}

.c-provider__name {
  font-size: 13px;
}

.c-providers__location {
  margin-bottom: 40px;
}
