/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  font-style: italic;
  border: 0;
  margin: 10px 0;
  padding: 10px 20px 10px 20px;
  color: #2e859c;
}
