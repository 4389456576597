/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background-color: #287b91;
  padding: 0 20px;
  min-height: 122px;
  color: $color-white;
}

.c-logo,
.c-search-form__container {
  @include bp-medium('max') {
    @include set-full-width();
    text-align: center;
  }
}

.c-search-form__container {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;

  @include bp-medium('max') {
    padding-top: 5px;
  }
}

.c-header__text-logo {
  text-align: right;
  margin: 15px 0 8px;

  @include bp-medium('max') {
    text-align: center;
  }
}

.c-search-form__field {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: $color-darker-grey;
  background-color: $color-white;
  border: 1px solid $color-medium-grey;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:hover {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.c-search-form__button {
  color: $color-white;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
