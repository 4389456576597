.c-pagination-pages {
  padding-top: 30px;
  display: flex;
}

.c-pagination-page {
  margin: 0 3px;

  &:before {
    display: none;
  }
}

.c-pagination-prev {
  margin: 0 3px 0 0;

  &:before {
    content: '\00AB \0020' !important;
    color: #0076a3;
    margin-left: -12px;
  }

  &--inactive:before {
    color: $color-darker-grey !important;
  }
}

.c-pagination-next {
  margin: 0 0 0 3px;

  &:before {
    display: none;
  }

  &:after {
    content: '\00BB \0020';
    color: #0076a3;
  }

  &--inactive:after {
    color: $color-darker-grey !important;
  }
}
