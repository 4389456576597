.c-patient-portal-page__title {
  .o-layout {
    justify-content: space-around;
    align-items: flex-start;
  }

  .c-btn {
    font-family: $font-family-1;
    flex: 0 0 auto;
    font-style: normal;
  }
}
