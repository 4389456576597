/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2em;
}

.c-page__container {
  padding: 20px 30px;
}

.c-page__content {
  margin-bottom: 40px;
}

.alignnone {
  margin: 0;
}

.aligncenter {
  display: block;
  margin: 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 5px 10px;

  @include bp-medium('max') {
    float: none;
    display: block;
    margin: 5px auto;
  }
}

.alignleft {
  float: left;
  margin: 5px 10px 5px 0;

  @include bp-medium('max') {
    float: none;
    display: block;
    margin: 5px auto;
  }
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
