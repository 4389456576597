/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-2;
  line-height: 1.1;
  font-weight: 500;

  &.u-normal { /* stylelint-disable-line selector-no-qualifying-type */
    font-weight: 400;
  }
}

h1, h2 {
  color: $color-blue;
}

h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3, h5, h6 {
  color: $color-grey;
}

h3, h4, h5, h6 {
  font-family: $font-family-1;
}

h1 {
  font-size: 35px;
  font-style: italic;
  border-bottom: 3px double $color-light-grey;
  margin-bottom: 15px;
  padding-bottom: 3px;

  /* stylelint-disable */

  &.u-no-underline,
  .c-page__text &:not(.u-double-line) {
    border-bottom: none;
  }

  /* stylelint-enable */
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 16px;
  color: $color-green;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}
