@charset "UTF-8";
/*
  Project: Wchealth
  Author: xfive
 */
/*
 * jQuery FlexSlider v2.7.1
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url("fonts/flexslider-icon.eot");
  src: url("fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("fonts/flexslider-icon.woff") format("woff"), url("fonts/flexslider-icon.ttf") format("truetype"), url("fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

@-moz-document url-prefix() {
  .loading .flex-viewport {
    max-height: none;
  }
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-direction-nav a.flex-next:before {
  content: '\f002';
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}

/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-enable */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 20px;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 10px;
}

p {
  margin: 0 0 10px;
}

strong {
  font-weight: 700;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  font-style: italic;
  border: 0;
  margin: 10px 0;
  padding: 10px 20px 10px 20px;
  color: #2e859c;
}

.c-embed--video {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin-bottom: 10px;
}

.c-embed--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6 {
  font-family: garamond, georgia, serif;
  line-height: 1.1;
  font-weight: 500;
}

h1.u-normal, h2.u-normal, h3.u-normal, h4.u-normal, h5.u-normal, h6.u-normal {
  /* stylelint-disable-line selector-no-qualifying-type */
  font-weight: 400;
}

h1, h2 {
  color: #2e859c;
}

h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3, h5, h6 {
  color: #666666;
}

h3, h4, h5, h6 {
  font-family: arial, sans-serif;
}

h1 {
  font-size: 35px;
  font-style: italic;
  border-bottom: 3px double #e5e5e5;
  margin-bottom: 15px;
  padding-bottom: 3px;
  /* stylelint-disable */
  /* stylelint-enable */
}

h1.u-no-underline,
.c-page__text h1:not(.u-double-line) {
  border-bottom: none;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 16px;
  color: #005e20;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
  height: 0;
  box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background-color: #e6e6e6;
  background-image: url("../assets/images/pattern_background.jpg");
  color: #666666;
  font-family: arial, sans-serif;
  font-size: 16px;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 0.875rem;
}

.c-main__content ul {
  color: #464646;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: 100%;
  height: auto;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #0076a3;
  text-decoration: none;
}

a:hover {
  color: #2a6496;
  text-decoration: underline;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
.c-page__container ul {
  list-style-type: none;
  margin: 0 0 10px;
  padding-left: 13px;
  color: #666666;
}

.c-page__container ul li {
  padding: 0;
}

.c-page__container ul li:before {
  content: '\00BB \0020';
  color: #0076a3;
  margin-left: -12px;
}

.c-page__container ul a:not(.c-btn):hover {
  color: #f26522;
  text-decoration: none;
}

.c-home__box-content ul, ul.u-list-color {
  font-family: georgia, garamond, serif;
}

.c-home__box-content ul li, ul.u-list-color li {
  padding: 0 0 6px;
}

.c-home__box-content ul li:before, ul.u-list-color li:before {
  color: #f26522;
}

.c-home__box-content ul a:not(.c-btn), ul.u-list-color a:not(.c-btn) {
  color: #464646;
}

li {
  list-style-position: inside;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout, .c-form--inline .gfield, .gallery {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.o-layout--auto {
  width: auto;
}

.o-layout--nowrap {
  flex-wrap: nowrap;
}

.o-layout--gutless {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}

.o-layout__item, .c-form--inline .gfield_label, .c-form--inline .ginput_container {
  flex: 1;
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.o-layout__item--gutless {
  padding: 0;
}

.o-layout__item--auto {
  width: auto;
  flex-basis: auto;
}

.o-layout__item--1 {
  width: 8.33333%;
  max-width: 8.33333%;
  flex-basis: 8.33333%;
}

.o-layout__item--2 {
  width: 16.66667%;
  max-width: 16.66667%;
  flex-basis: 16.66667%;
}

.o-layout__item--3 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.o-layout__item--4 {
  width: 33.33333%;
  max-width: 33.33333%;
  flex-basis: 33.33333%;
}

.o-layout__item--5 {
  width: 41.66667%;
  max-width: 41.66667%;
  flex-basis: 41.66667%;
}

.o-layout__item--6 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.o-layout__item--7 {
  width: 58.33333%;
  max-width: 58.33333%;
  flex-basis: 58.33333%;
}

.o-layout__item--8 {
  width: 66.66667%;
  max-width: 66.66667%;
  flex-basis: 66.66667%;
}

.o-layout__item--9 {
  width: 75%;
  max-width: 75%;
  flex-basis: 75%;
}

.o-layout__item--10 {
  width: 83.33333%;
  max-width: 83.33333%;
  flex-basis: 83.33333%;
}

.o-layout__item--11 {
  width: 91.66667%;
  max-width: 91.66667%;
  flex-basis: 91.66667%;
}

.o-layout__item--12 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.o-layout__item--20 {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

@media (min-width: 768px) {
  .o-layout__item--small-1 {
    width: 8.33333%;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .o-layout__item--small-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
  .o-layout__item--small-3, .c-form--inline .gfield_label {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .o-layout__item--small-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
  .o-layout__item--small-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex-basis: 41.66667%;
  }
  .o-layout__item--small-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .o-layout__item--small-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex-basis: 58.33333%;
  }
  .o-layout__item--small-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }
  .o-layout__item--small-9, .c-form--inline .ginput_container {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .o-layout__item--small-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex-basis: 83.33333%;
  }
  .o-layout__item--small-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex-basis: 91.66667%;
  }
  .o-layout__item--small-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .o-layout__item--small-20 {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 992px) {
  .o-layout__item--medium-1 {
    width: 8.33333%;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .o-layout__item--medium-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
  .o-layout__item--medium-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .o-layout__item--medium-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
  .o-layout__item--medium-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex-basis: 41.66667%;
  }
  .o-layout__item--medium-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .o-layout__item--medium-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex-basis: 58.33333%;
  }
  .o-layout__item--medium-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }
  .o-layout__item--medium-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .o-layout__item--medium-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex-basis: 83.33333%;
  }
  .o-layout__item--medium-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex-basis: 91.66667%;
  }
  .o-layout__item--medium-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .o-layout__item--medium-20 {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1200px) {
  .o-layout__item--large-1 {
    width: 8.33333%;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .o-layout__item--large-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
  .o-layout__item--large-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .o-layout__item--large-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
  .o-layout__item--large-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex-basis: 41.66667%;
  }
  .o-layout__item--large-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .o-layout__item--large-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex-basis: 58.33333%;
  }
  .o-layout__item--large-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }
  .o-layout__item--large-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .o-layout__item--large-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex-basis: 83.33333%;
  }
  .o-layout__item--large-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex-basis: 91.66667%;
  }
  .o-layout__item--large-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .o-layout__item--large-20 {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

.o-layout--guttered {
  justify-content: space-between;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  max-width: 100%;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-wrapper__inner {
  max-width: 1050px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .o-wrapper__inner {
    max-width: 970px;
  }
}

@media (max-width: 992px) {
  .o-wrapper__inner {
    max-width: 750px;
  }
}

.c-box-list {
  margin-bottom: 30px;
}

.c-box-list--patient_portal {
  margin: 30px 0;
}

.c-box-list__inner {
  min-height: 265px;
  margin-top: 5px;
  padding-bottom: 10px;
  background-color: #fff;
  background: #add8e3;
  background: -moz-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(95%, #add8e3));
  background: -webkit-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -o-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: linear-gradient(to bottom, #ffffff 0%, #add8e3 95%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#add8e3);
}

.c-box-list__content {
  padding: 10px 13px 0 16px;
  line-height: 1.6;
  font-family: georgia, garamond, serif;
}

.c-box-list__header {
  color: #fff;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  background-color: #45acc6;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background: #297c92;
  background: -moz-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #45acc6), color-stop(100%, #297c92));
  background: -webkit-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -o-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: linear-gradient(to bottom, #45acc6 10%, #297c92 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#45acc6, endColorstr=#297c92);
}

.c-box-list__list-item {
  padding-bottom: 3px !important;
}

.c-box-list__list-item--button {
  width: calc(100% + 13px);
  margin-top: 10px;
  margin-left: -13px;
  text-align: center;
}

.c-box-list__list-item--button:before {
  display: none;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
/* stylelint-disable */
.c-btn,
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type='submit'],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type='submit'],
.post-password-form input[type=submit] {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  font-size: 14px;
  line-height: 1.428571429;
  font-weight: 400;
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 6px 12px;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
  user-select: none;
  text-decoration: none !important;
  outline: none !important;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f1b650;
  background: #ef6730;
  background: -moz-linear-gradient(top, #f1b650 0%, #ef6730 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f1b650), color-stop(100%, #ef6730));
  background: -webkit-linear-gradient(top, #f1b650 0%, #ef6730 100%);
  background: -o-linear-gradient(top, #f1b650 0%, #ef6730 100%);
  background: linear-gradient(to bottom, #f1b650 0%, #ef6730 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#f1b650, endColorstr=#ef6730);
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #ef6730;
  background: #f1b650;
  background: -moz-linear-gradient(top, #ef6730 0%, #f1b650 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ef6730), color-stop(100%, #f1b650));
  background: -webkit-linear-gradient(top, #ef6730 0%, #f1b650 100%);
  background: -o-linear-gradient(top, #ef6730 0%, #f1b650 100%);
  background: linear-gradient(to bottom, #ef6730 0%, #f1b650 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#ef6730, endColorstr=#f1b650);
}

.c-btn--submit {
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 0;
  border: #536813 1px solid;
  background: #3e711b;
  background: -moz-linear-gradient(top, #6faf3d 0%, #3e711b 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6faf3d), color-stop(100%, #3e711b));
  background: -webkit-linear-gradient(top, #6faf3d 0%, #3e711b 100%);
  background: -o-linear-gradient(top, #6faf3d 0%, #3e711b 100%);
  background: linear-gradient(to bottom, #6faf3d 0%, #3e711b 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#6faf3d, endColorstr=#3e711b);
  box-shadow: inset 1px 2px 0 0 rgba(255, 255, 255, 0.35);
}

.c-btn--submit, .c-btn--submit:hover, .c-btn--submit:active, .c-btn--submit:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--submit:hover, .c-btn--submit:focus {
  background-color: #3e711b;
  background: #6faf3d;
  background: -moz-linear-gradient(top, #3e711b 0%, #6faf3d 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e711b), color-stop(100%, #6faf3d));
  background: -webkit-linear-gradient(top, #3e711b 0%, #6faf3d 100%);
  background: -o-linear-gradient(top, #3e711b 0%, #6faf3d 100%);
  background: linear-gradient(to bottom, #3e711b 0%, #6faf3d 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#3e711b, endColorstr=#6faf3d);
}

.c-btn--info,
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type='submit'],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type='submit'],
.post-password-form input[type=submit] {
  border: #46b8da 1px solid;
  background-color: #5bc0de;
  border-radius: 4px;
}

.c-btn--info, .c-btn--info:hover, .c-btn--info:active, .c-btn--info:focus,
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input.button:hover,
.gform_wrapper .gform_footer input.button:active,
.gform_wrapper .gform_footer input.button:focus,
.gform_wrapper .gform_footer input[type='submit'],
.gform_wrapper .gform_footer input[type='submit']:hover,
.gform_wrapper .gform_footer input[type='submit']:active,
.gform_wrapper .gform_footer input[type='submit']:focus,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input.button:hover,
.gform_wrapper .gform_page_footer input.button:active,
.gform_wrapper .gform_page_footer input.button:focus,
.gform_wrapper .gform_page_footer input[type='submit'],
.gform_wrapper .gform_page_footer input[type='submit']:hover,
.gform_wrapper .gform_page_footer input[type='submit']:active,
.gform_wrapper .gform_page_footer input[type='submit']:focus,
.post-password-form input[type=submit],
.post-password-form input[type=submit]:hover,
.post-password-form input[type=submit]:active,
.post-password-form input[type=submit]:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--info:hover, .c-btn--info:focus,
.gform_wrapper .gform_footer input.button:hover,
.gform_wrapper .gform_footer input.button:focus,
.gform_wrapper .gform_footer input[type='submit']:hover,
.gform_wrapper .gform_footer input[type='submit']:focus,
.gform_wrapper .gform_page_footer input.button:hover,
.gform_wrapper .gform_page_footer input.button:focus,
.gform_wrapper .gform_page_footer input[type='submit']:hover,
.gform_wrapper .gform_page_footer input[type='submit']:focus,
.post-password-form input[type=submit]:hover,
.post-password-form input[type=submit]:focus {
  background-color: #39b3d7;
  border-color: #269abc;
}

.c-btn--submit-alt {
  display: block;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 0;
  border: #46b8da 1px solid;
  background-color: #5bc0de;
}

.c-btn--submit-alt, .c-btn--submit-alt:hover, .c-btn--submit-alt:active, .c-btn--submit-alt:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--submit-alt:hover, .c-btn--submit-alt:focus {
  background-color: #39b3d7;
  border-color: #269abc;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--full-width {
  width: 100%;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-content-box {
  margin-top: 5px;
  padding-bottom: 10px;
  background-color: #fff;
  background: #add8e3;
  background: -moz-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(95%, #add8e3));
  background: -webkit-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -o-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: linear-gradient(to bottom, #ffffff 0%, #add8e3 95%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#add8e3);
}

.c-content-box__content {
  padding: 20px 30px;
  line-height: 1.6;
}

.c-content-box__heading {
  color: #fff;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background-color: #70b23f;
  background: #427e1f;
  background: -moz-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(7%, #70b23f), color-stop(100%, #427e1f));
  background: -webkit-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -o-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: linear-gradient(to bottom, #70b23f 7%, #427e1f 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#70b23f, endColorstr=#427e1f);
}

.c-director__name {
  text-transform: uppercase;
}

.c-events-page__title .o-layout, .c-events-page__title .c-form--inline .gfield, .c-form--inline .c-events-page__title .gfield, .c-events-page__title .gallery {
  justify-content: space-around;
  align-items: flex-start;
}

.c-events-page__title .c-btn {
  font-family: arial, sans-serif;
  flex: 0 0 auto;
  font-style: normal;
}

.c-event__left .c-btn {
  display: block;
  margin: 0 auto;
  max-width: 105px;
}

.c-events__list-item {
  min-height: 265px;
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #fff;
  background: #bfe1e9;
  background: -moz-linear-gradient(top, #fff 0%, #bfe1e9 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #bfe1e9));
  background: -webkit-linear-gradient(top, #fff 0%, #bfe1e9 100%);
  background: -o-linear-gradient(top, #fff 0%, #bfe1e9 100%);
  background: linear-gradient(to bottom, #fff 0%, #bfe1e9 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#fff, endColorstr=#bfe1e9);
}

.c-events__list-header {
  margin: 0 0 10px;
  padding: 7px 3px;
  color: #fff;
  background: #70b23f;
  background: #427e1f;
  background: -moz-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(7%, #70b23f), color-stop(100%, #427e1f));
  background: -webkit-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -o-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: linear-gradient(to bottom, #70b23f 7%, #427e1f 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#70b23f, endColorstr=#427e1f);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  font-size: 1.4375rem;
  text-align: center;
}

.c-event {
  padding: 10px 30px 20px;
}

.c-event__date-box {
  text-align: center;
  padding: 13px 4px 17px;
  margin: 0 10px 30px;
  background: #2e859c;
  font-size: 1.25rem;
  line-height: 1;
  color: #fff;
  border-radius: 10px;
  font-family: garamond, georgia, serif;
}

@media (max-width: 768px) {
  .c-event__date-box {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-event__day-no {
  display: block;
  font-size: 2.625rem;
}

.c-event__title {
  margin-top: 5px;
  font-size: 1.25rem;
  color: #2e859c;
}

.c-event__content h1, .c-event__content h2, .c-event__content h3, .c-event__content h4, .c-event__content h5, .c-event__content h6 {
  margin: 0 0 10px;
  line-height: 1.4;
  color: #666666;
}

.c-event__content h2 {
  font-size: 1.3125rem;
}

.c-event__content h3 {
  font-size: 1.125rem;
}

.c-event__content h4 {
  font-size: 1rem;
}

.c-event__content h6 {
  font-size: 0.8125rem;
}

.c-event__date {
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 10px;
  color: #2e859c;
  font-family: georgia, garamond, serif;
}

.c-event__info-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}

.c-event__info-item strong {
  display: block;
  min-width: 114px;
  max-width: 114px;
}

.c-events__past-link {
  text-align: right;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer__boxes {
  padding: 10px 0 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #e9e8e8;
  align-items: flex-start;
  justify-content: center;
}

.c-footer__box {
  max-width: 273px;
  text-align: left;
  margin: 1.5%;
  border: 4px solid #fff;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.25);
}

@media (min-width: 640px) {
  .c-footer__box--break {
    display: none;
  }
}

.c-footer__box-inner {
  padding-right: 10px;
  align-items: flex-start;
  background-color: #f5f5f5;
  color: inherit;
  background: #dadcdf;
  background: -moz-linear-gradient(top, #f5f5f5 50%, #dadcdf 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #f5f5f5), color-stop(100%, #dadcdf));
  background: -webkit-linear-gradient(top, #f5f5f5 50%, #dadcdf 100%);
  background: -o-linear-gradient(top, #f5f5f5 50%, #dadcdf 100%);
  background: linear-gradient(to bottom, #f5f5f5 50%, #dadcdf 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#f5f5f5, endColorstr=#dadcdf);
}

.c-footer__box-inner:hover {
  text-decoration: none;
  color: inherit;
  background: #edf8ff;
  background: -moz-linear-gradient(top, #fff 30%, #edf8ff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #fff), color-stop(100%, #edf8ff));
  background: -webkit-linear-gradient(top, #fff 30%, #edf8ff 100%);
  background: -o-linear-gradient(top, #fff 30%, #edf8ff 100%);
  background: linear-gradient(to bottom, #fff 30%, #edf8ff 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#fff, endColorstr=#edf8ff);
}

.c-footer__box-image {
  margin-right: 10px;
}

.c-footer__box-title {
  padding-top: 4px;
  padding-bottom: 2px;
  font-weight: 400;
  margin: 0;
  font-size: 1.3125rem;
}

.c-footer__box-subtitle {
  margin: 0;
  color: #005e20;
  font-size: 13px;
  text-transform: uppercase;
  font-family: arial, sans-serif;
}

.c-footer__box-text {
  margin: 0;
  padding-top: 4px;
  line-height: 1.3;
  font-size: 12px;
}

.c-footer__newlsetter {
  margin-top: 15px;
  padding-top: 0;
  padding-bottom: 15px;
  text-align: center;
  color: #898989;
  background: transparent;
}

@media (max-width: 640px) {
  .c-footer__newlsetter {
    padding-bottom: 0;
  }
  .c-footer__newlsetter .c-form__group {
    margin-bottom: 5px;
  }
}

.c-footer__newlsetter .c-form__control {
  width: 250px;
}

@media (max-width: 640px) {
  .c-footer__newlsetter .c-form__control {
    margin-left: auto;
    margin-right: auto;
  }
}

.c-footer__newlsetter form {
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .c-footer__newlsetter form {
    display: block;
  }
}

.c-footer__newlsetter label {
  margin-right: 5px;
}

.c-footer__newlsetter .c-btn {
  margin-left: 10px;
}

.c-footer__bottom {
  padding: 5px 30px;
  background: #287b91;
}

.c-footer__bottom * {
  color: #fff;
}

.c-footer__bottom .c-widget__title {
  padding-bottom: 10px;
  color: #7bdff9;
}

.c-footer__bottom a:hover {
  color: #7bdff9;
  text-decoration: none;
}

.c-footer__bottom hr {
  margin-top: 10px;
  margin-bottom: 20px;
  border-top: 1px solid #7bdff9;
}

.c-footer__bottom-content p:last-of-type {
  margin-bottom: 0;
}

.c-footer__bottom-credits ul {
  padding: 0;
  margin: 0 0 10px;
  list-style-type: none;
}

.c-footer__bottom-credits li {
  display: inline-block;
  padding-right: 10px;
  font-size: 11px;
}

/* stylelint-disable */
.c-form__group {
  margin-bottom: 10px;
}

.c-form__control,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper textarea,
.post-password-form input:not([type=submit]) {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.c-form__control:focus,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):focus,
.gform_wrapper textarea:focus,
.post-password-form input:not([type=submit]):focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.c-form__control:-moz-placeholder,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):-moz-placeholder,
.gform_wrapper textarea:-moz-placeholder,
.post-password-form input:not([type=submit]):-moz-placeholder {
  color: #999999;
}

.c-form__control::-moz-placeholder,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])::-moz-placeholder,
.gform_wrapper textarea::-moz-placeholder,
.post-password-form input:not([type=submit])::-moz-placeholder {
  color: #999999;
}

.c-form__control:-ms-input-placeholder,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):-ms-input-placeholder,
.gform_wrapper textarea:-ms-input-placeholder,
.post-password-form input:not([type=submit]):-ms-input-placeholder {
  color: #999999;
}

.c-form__control::-webkit-input-placeholder,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])::-webkit-input-placeholder,
.gform_wrapper textarea::-webkit-input-placeholder,
.post-password-form input:not([type=submit])::-webkit-input-placeholder {
  color: #999999;
}

label {
  color: #666666;
}

.gform_wrapper textarea.medium {
  height: 100px !important;
}

.gform_wrapper .gform_footer {
  margin: 0 !important;
}

.gform_wrapper ul.gform_fields li.gfield {
  padding: 0 !important;
  margin-top: 15px !important;
}

.gform_wrapper .validation_error {
  padding: 5px 0 !important;
  margin: 0 !important;
  background-color: #fff !important;
  color: #cd291a !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
}

.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  min-width: 100% !important;
}

.gform_wrapper .gfield_error {
  background-color: transparent !important;
  min-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
  margin: 0 !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
  padding: 0 !important;
  color: #cd291a !important;
}

.gform_wrapper .gfield_error .gfield_label {
  color: #666666 !important;
}

.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper li.gfield_error textarea {
  border: 1px solid #cccccc !important;
}

@media (min-width: 768px) {
  .c-form--inline {
    padding-right: 30px;
  }
}

.c-form--inline .gfield_label {
  text-align: right;
}

@media (max-width: 768px) {
  .c-form--inline .gfield_label {
    text-align: left;
    padding: 0;
  }
}

.c-form--inline .ginput_container {
  padding-right: 15px !important;
}

@media (max-width: 768px) {
  .c-form--inline .ginput_container {
    padding: 0 !important;
  }
}

@media (min-width: 768px) {
  .c-form--inline .gfield_description {
    margin-left: calc(25% + 15px) !important;
    width: calc(75% - 15px) !important;
  }
}

@media (min-width: 768px) {
  .c-form--inline .gform_footer {
    padding-left: calc(25% + 15px) !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0 1000px #fff inset !important;
}

.c-google__map {
  height: 300px;
  margin-bottom: 20px;
}

.c-google-translate__dropdown {
  display: flex;
  padding: 0 30px 20px;
  justify-content: flex-end;
  align-items: center;
  font-family: georgia, garamond, serif;
  color: #2e859c;
}

.c-google-translate__element {
  padding-left: 8px;
}

.c-content--es {
  display: none;
}

.c-body--lang-es .c-content--es {
  display: block;
}

.c-body--lang-es .c-content--en {
  display: none;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background-color: #287b91;
  padding: 0 20px;
  min-height: 122px;
  color: #fff;
}

@media (max-width: 768px) {
  .c-logo,
  .c-search-form__container {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
}

.c-search-form__container {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
}

@media (max-width: 768px) {
  .c-search-form__container {
    padding-top: 5px;
  }
}

.c-header__text-logo {
  text-align: right;
  margin: 15px 0 8px;
}

@media (max-width: 768px) {
  .c-header__text-logo {
    text-align: center;
  }
}

.c-search-form__field {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.c-search-form__field:hover {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.c-search-form__button {
  color: #fff;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.c-home-slider .flexslider {
  margin: 0;
  padding: 4px 2px 2px;
  border: none;
}

.c-home__box-inner {
  margin-top: 15px;
  padding-bottom: 10px;
  background-color: #fff;
  background: #add8e3;
  background: -moz-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(95%, #add8e3));
  background: -webkit-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: -o-linear-gradient(top, #ffffff 0%, #add8e3 95%);
  background: linear-gradient(to bottom, #ffffff 0%, #add8e3 95%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#add8e3);
}

@media (min-width: 768px) {
  .c-home__box-inner {
    min-height: 265px;
  }
}

.c-home__content h2 {
  margin-top: 0;
}

@media (max-width: 992px) {
  .c-home__content--right {
    width: 100%;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-home__box-content {
  padding: 10px 13px 0 16px;
  line-height: 1.6;
  font-family: georgia, garamond, serif;
}

.c-home__box-header {
  color: #fff;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background-color: #70b23f;
  background: #427e1f;
  background: -moz-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(7%, #70b23f), color-stop(100%, #427e1f));
  background: -webkit-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -o-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: linear-gradient(to bottom, #70b23f 7%, #427e1f 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#70b23f, endColorstr=#427e1f);
}

.c-home__bottom-content {
  padding: 20px 0 10px;
  margin-top: 10px;
  border-top: 3px double #e5e5e5;
}

.c-location__image {
  max-width: 170px;
  border: 1px solid #e5e5e5;
}

@media (max-width: 992px) {
  .c-location__image {
    max-width: 215px;
    width: 100%;
  }
}

.c-location__hours-header {
  padding-top: 20px;
  padding-bottom: 9px;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.0625rem;
  color: #666666;
  text-transform: uppercase;
  border-bottom: 3px double #e5e5e5;
  font-family: arial, sans-serif;
}

.c-location__content {
  padding-top: 5px;
}

.c-location__hours {
  margin-bottom: 10px;
}

.c-location__hour-info {
  padding: 1% 5%;
  font-style: italic;
  text-align: center;
}

.c-location__map-header {
  font-family: arial, sans-serif;
  font-size: 1rem;
}

.c-location__payments-heading,
.c-location__services-heading {
  text-transform: uppercase;
}

.c-location__medical-providers-heading {
  color: #005e20;
}

.c-location__map-element {
  height: 200px;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav__navigation {
  background: #297c92;
  background: -moz-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #45acc6), color-stop(100%, #297c92));
  background: -webkit-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -o-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: linear-gradient(to bottom, #45acc6 10%, #297c92 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#45acc6, endColorstr=#297c92);
}

@media (max-width: 768px) {
  .c-main-nav__navigation {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.c-main-nav {
  display: flex;
  margin: 0;
  border-top: 2px solid #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background: #297c92;
  background: -moz-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #45acc6), color-stop(100%, #297c92));
  background: -webkit-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: -o-linear-gradient(top, #45acc6 10%, #297c92 100%);
  background: linear-gradient(to bottom, #45acc6 10%, #297c92 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#45acc6, endColorstr=#297c92);
}

@media (max-width: 768px) {
  .c-main-nav {
    border-top: none;
    display: none;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .c-main-nav__item {
    border-right: 2px solid #fff;
  }
}

.c-main-nav__item:last-child {
  border-right: none;
}

@media (min-width: 768px) {
  .c-main-nav__item:hover > .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__item:hover .c-main-nav__link, .c-main-nav__item.current-menu-parent .c-main-nav__link, .c-main-nav__item.is-active .c-main-nav__link {
  background: #70b23f;
  background: #427e1f;
  background: -moz-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(7%, #70b23f), color-stop(100%, #427e1f));
  background: -webkit-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: -o-linear-gradient(top, #70b23f 7%, #427e1f 100%);
  background: linear-gradient(to bottom, #70b23f 7%, #427e1f 100%);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#70b23f, endColorstr=#427e1f);
}

.c-main-nav__link {
  display: block;
  padding: 10px 36.5px;
  text-decoration: none !important;
  font-size: 1em;
  color: #fff !important;
  text-transform: uppercase;
}

.c-body--istranslated .c-main-nav__link {
  padding: 10px 29px;
}

@media (max-width: 1200px) {
  .c-main-nav__link {
    padding: 10px 31px;
  }
}

@media (max-width: 992px) {
  .c-main-nav__link {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .c-main-nav__item-text:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 0 dotted;
  }
  .c-main-nav__link:active .c-main-nav__item-text:after {
    border-top: 4px solid #2a6496;
  }
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
  min-width: 160px;
  background: #d5dde0;
  border: 0 solid #d5dde0;
  padding: 15px 0;
  border-radius: 0;
}

.c-main-nav__subitem .c-main-nav__dropdown {
  padding: 0;
  left: 100%;
  top: 0;
  max-width: 160px;
}

@media (max-width: 768px) {
  .c-main-nav__subitem .c-main-nav__dropdown {
    top: auto;
    left: auto;
  }
}

@media (max-width: 768px) {
  .c-main-nav__dropdown {
    position: relative;
    min-width: 1px;
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
}

.c-main-nav__subitem {
  position: relative;
  list-style: none;
  margin: 0;
}

@media (min-width: 768px) {
  .c-main-nav__subitem:hover > .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__subsubitem {
  position: relative;
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink,
.c-main-nav__subsublink {
  display: block;
  padding: 6px 20px;
  text-decoration: none !important;
  color: #636363 !important;
}

.current-menu-item .c-main-nav__sublink, .c-main-nav__sublink:hover, .current-menu-item
.c-main-nav__subsublink,
.c-main-nav__subsublink:hover {
  background-color: #f59231;
  color: #fff !important;
}

@media (max-width: 768px) {
  .c-main-nav__sublink,
  .c-main-nav__subsublink {
    padding: 5px 15px 5px 25px;
    color: #fff !important;
  }
}

.c-main-nav__toggle {
  position: relative;
  margin: 5px 15px 5px 0;
  padding: 9px 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .c-main-nav__toggle {
    display: flex;
    align-content: center;
  }
}

.c-main-nav__toggle-text {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding-right: 10px;
  line-height: 1;
  position: relative;
}

.c-main-nav__toggle-icon-bar {
  background: #fff;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.c-main-nav__toggle-icon-bar + .c-main-nav__toggle-icon-bar {
  margin-top: 4px;
}

.c-nav__side-header {
  margin-top: 0;
}

.c-news-topics__heading {
  text-transform: uppercase;
  color: #2e859c;
}

.c-news__back-link {
  display: inline-block;
  margin-bottom: 10px;
  font-family: georgia, garamond, serif;
}

.c-news__back-link:before {
  content: '\00AB \0020';
  color: #464646;
}

@media (max-width: 768px) {
  .c-news-post__header {
    flex-wrap: wrap;
  }
}

.c-news-post__title {
  margin-top: 0;
}

.c-news-post__excerpt {
  padding-top: 10px;
}

.c-news-single__content {
  padding-top: 10px;
  overflow: hidden;
}

.c-news-single__image {
  float: right;
  max-width: 210px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .c-news-single__image {
    float: none;
    display: block;
    margin: 5px auto;
    max-width: 100%;
    text-align: center;
  }
  .c-news-single__image img {
    display: block;
    margin: 5px auto;
  }
}

.c-news-post__image {
  text-align: right;
}

@media (max-width: 768px) {
  .c-news-post__image {
    padding-bottom: 5px;
    text-align: center;
    order: -1;
  }
}

@media (max-width: 768px) {
  .c-news-post__image,
  .c-news-post__meta {
    min-width: 1px;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.c-news-single__date {
  display: inline-block;
  font-size: 12.5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.c-news-single__video-caption,
.c-news-single__image-caption {
  font-size: 12.5px;
}

.c-pagination-pages {
  padding-top: 30px;
  display: flex;
}

.c-pagination-page {
  margin: 0 3px;
}

.c-pagination-page:before {
  display: none;
}

.c-pagination-prev {
  margin: 0 3px 0 0;
}

.c-pagination-prev:before {
  content: '\00AB \0020' !important;
  color: #0076a3;
  margin-left: -12px;
}

.c-pagination-prev--inactive:before {
  color: #555555 !important;
}

.c-pagination-next {
  margin: 0 0 0 3px;
}

.c-pagination-next:before {
  display: none;
}

.c-pagination-next:after {
  content: '\00BB \0020';
  color: #0076a3;
}

.c-pagination-next--inactive:after {
  color: #555555 !important;
}

.c-patient-portal-page__title .o-layout, .c-patient-portal-page__title .c-form--inline .gfield, .c-form--inline .c-patient-portal-page__title .gfield, .c-patient-portal-page__title .gallery {
  justify-content: space-around;
  align-items: flex-start;
}

.c-patient-portal-page__title .c-btn {
  font-family: arial, sans-serif;
  flex: 0 0 auto;
  font-style: normal;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-page__container {
  padding: 20px 30px;
}

.c-page__content {
  margin-bottom: 40px;
}

.alignnone {
  margin: 0;
}

.aligncenter {
  display: block;
  margin: 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 5px 10px;
}

@media (max-width: 768px) {
  .alignright {
    float: none;
    display: block;
    margin: 5px auto;
  }
}

.alignleft {
  float: left;
  margin: 5px 10px 5px 0;
}

@media (max-width: 768px) {
  .alignleft {
    float: none;
    display: block;
    margin: 5px auto;
  }
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-press-post__header {
  align-items: flex-start;
}

@media (max-width: 768px) {
  .c-press-post__header {
    flex-wrap: wrap;
  }
}

.c-press-post__title {
  margin-top: 0;
}

.c-press-post__image {
  min-width: 250px;
  text-align: right;
}

@media (max-width: 768px) {
  .c-press-post__image {
    padding-bottom: 5px;
    text-align: center;
    order: -1;
  }
}

@media (max-width: 768px) {
  .c-press-post__image,
  .c-press-post__meta {
    min-width: 1px;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.c-press-post__content {
  padding-top: 10px;
}

.c-press__contact {
  margin-bottom: 30px;
}

.c-press__contact-heading {
  margin: 0 0 15px;
  padding-top: 20px;
  padding-bottom: 9px;
  font-size: 1.0625rem;
  text-transform: uppercase;
  border-bottom: 3px double #e5e5e5;
  font-family: arial, sans-serif;
  color: #666666;
}

.c-press__contact-content strong {
  color: #005e20;
}

.c-recent-news__heading {
  text-transform: uppercase;
  color: #005e20 !important;
}

.c-recent-news__content {
  overflow: hidden;
}

.c-recent-news__image {
  float: right;
  max-width: 80px;
  margin-left: 15px;
}

.c-recent-news__more {
  margin-left: 12px;
  color: #464646;
}

.c-recent-news__more:hover {
  text-decoration: none;
  color: #f26522;
}

.c-recent-news__more:before {
  content: '\00BB \0020';
  color: #f26522;
  margin-left: -12px;
}

.c-related-posts__heading {
  text-transform: uppercase;
  color: #2e859c;
}

.rwd {
  display: none;
  opacity: 0;
}

@media (max-width: 1200px) {
  .rwd--1200 {
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .rwd--992 {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .rwd--768 {
    opacity: 1;
  }
}

.c-search__result-title {
  text-transform: uppercase;
}

.c-service__services-heading,
.c-service__more-about-heading {
  color: #005e20;
}

.c-service__payments-heading,
.c-service__locations-heading {
  text-transform: uppercase;
}

.c-service__locations {
  margin-bottom: 10px;
}

.c-service__extra-content {
  padding-top: 20px;
}

@media (max-width: 992px) {
  .c-sidebar {
    width: 100%;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .c-sidebar--left {
    order: 2;
  }
}

.c-site-container {
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

.c-leaders {
  margin-bottom: 50px;
}

.c-providers__entry,
.c-leaders__entry {
  padding-bottom: 30px;
}

.c-providers__location-name {
  margin-bottom: 20px;
  text-transform: uppercase;
}

.c-provider {
  text-align: center;
}

.c-provider__name {
  font-size: 13px;
}

.c-providers__location {
  margin-bottom: 40px;
}

.c-upcoming-events {
  margin-bottom: 40px;
}

.c-upcoming-events__heading {
  text-transform: uppercase;
  color: #005e20 !important;
}

.c-upcoming-events__item {
  margin-bottom: 30px;
}

.c-upcoming-event__more-link {
  margin-left: 30px;
  color: #464646;
}

.c-upcoming-event__more-link:hover {
  text-decoration: none;
  color: #f26522;
}

.c-upcoming-event__more-link:before {
  content: '\00BB \0020';
  color: #f26522;
  margin-left: -12px;
}

.c-upcoming-event__button {
  text-transform: uppercase;
  text-align: right;
}

.c-vision {
  margin-bottom: 40px;
}

.c-vision__heading {
  margin: 0 0 15px;
  padding-top: 0;
  padding-bottom: 9px;
  font-size: 1.0625rem;
  text-transform: uppercase;
  border-bottom: 3px double #e5e5e5;
  font-family: arial, sans-serif;
  color: #666666;
}

.c-vision__item-heading {
  color: #2e859c;
  font-family: georgia, garamond, serif;
}

.c-volunteer__singup {
  margin-bottom: 40px;
}

.c-volunteer__singup-heading {
  margin: 0 0 15px;
  padding-top: 0;
  padding-bottom: 9px;
  font-size: 1.0625rem;
  text-transform: uppercase;
  border-bottom: 3px double #e5e5e5;
  font-family: arial, sans-serif;
  color: #666666;
}

.c-widget__title {
  font-family: arial, sans-serif;
  text-transform: uppercase;
  color: #2e859c;
}

.c-widget ul {
  list-style-type: none;
  margin: 0 0 10px;
  padding-left: 13px;
  color: #464646;
  font-family: georgia, garamond, serif;
}

.c-widget ul li {
  padding: 0 0 6px;
}

.c-widget ul li:before {
  color: #f26522;
}

.c-widget ul a:not(.c-btn) {
  color: #464646;
}

.widget_nav_menu ul {
  list-style-type: none;
  margin: 0 0 10px;
  padding-left: 0;
  color: #fff;
  font-family: arial, sans-serif;
}

.widget_nav_menu ul li {
  font-size: 12px;
  line-height: 18px;
  padding: 0 0 8px;
}

.widget_nav_menu ul li:before {
  display: none;
}

.widget_nav_menu ul a:not(.c-btn) {
  color: #fff;
}

.widget_nav_menu ul a:hover {
  color: #7bdff9;
  text-decoration: none;
}

.gallery-item {
  padding: 5px;
}

.gallery-item img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
}

.gallery-icon {
  width: 100%;
}

.gallery-columns-1 .gallery-item {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.33333%;
  max-width: 33.33333%;
  flex-basis: 33.33333%;
}

@media (max-width: 480px) {
  .gallery-columns-3 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-4 .gallery-item {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

@media (max-width: 640px) {
  .gallery-columns-4 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-4 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-5 .gallery-item {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

@media (max-width: 768px) {
  .gallery-columns-5 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 640px) {
  .gallery-columns-5 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-5 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-6 .gallery-item {
  width: 16.66667%;
  max-width: 16.66667%;
  flex-basis: 16.66667%;
}

@media (max-width: 1152px) {
  .gallery-columns-6 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 992px) {
  .gallery-columns-6 .gallery-item {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 768px) {
  .gallery-columns-6 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 640px) {
  .gallery-columns-6 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-6 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-7 .gallery-item {
  width: 14.28571%;
  max-width: 14.28571%;
  flex-basis: 14.28571%;
}

@media (max-width: 1200px) {
  .gallery-columns-7 .gallery-item {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}

@media (max-width: 1152px) {
  .gallery-columns-7 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 992px) {
  .gallery-columns-7 .gallery-item {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 768px) {
  .gallery-columns-7 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 640px) {
  .gallery-columns-7 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-7 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-8 .gallery-item {
  width: 12.5%;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

@media (max-width: 1200px) {
  .gallery-columns-8 .gallery-item {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}

@media (max-width: 1152px) {
  .gallery-columns-8 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 992px) {
  .gallery-columns-8 .gallery-item {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 768px) {
  .gallery-columns-8 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 640px) {
  .gallery-columns-8 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-8 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.gallery-columns-9 .gallery-item {
  width: 11.11111%;
  max-width: 11.11111%;
  flex-basis: 11.11111%;
}

@media (max-width: 1200px) {
  .gallery-columns-9 .gallery-item {
    width: 16.66667%;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}

@media (max-width: 1152px) {
  .gallery-columns-9 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 992px) {
  .gallery-columns-9 .gallery-item {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 768px) {
  .gallery-columns-9 .gallery-item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 640px) {
  .gallery-columns-9 .gallery-item {
    width: 33.33333%;
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 480px) {
  .gallery-columns-9 .gallery-item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-double-line {
  border-bottom: 3px double #e5e5e5;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-list-two-cols {
  column-count: 2;
}

@media (max-width: 768px) {
  .u-list-two-cols {
    column-count: 1;
  }
}

.u-no-margin {
  margin: 0;
}
