.c-news__back-link {
  display: inline-block;
  margin-bottom: 10px;
  font-family: $font-family-3;

  &:before {
    content: '\00AB \0020';
    color: $color-dark-grey;
  }
}

.c-news-post__header {
  @include bp-medium('max') {
    flex-wrap: wrap;
  }
}

.c-news-post__title {
  margin-top: 0;
}

.c-news-post__excerpt {
  padding-top: 10px;
}

.c-news-single__content {
  padding-top: 10px;
  overflow: hidden;
}

.c-news-single__image {
  float: right;
  max-width: 210px;
  margin-left: 10px;

  @include bp-medium('max') {
    float: none;
    display: block;
    margin: 5px auto;
    max-width: 100%;
    text-align: center;

    img {
      display: block;
      margin: 5px auto;
    }
  }
}

.c-news-post__image {
  text-align: right;

  @include bp-medium('max') {
    padding-bottom: 5px;
    text-align: center;
    order: -1;
  }
}

.c-news-post__image,
.c-news-post__meta {
  @include bp-medium('max') {
    min-width: 1px;
    @include set-full-width();
  }
}

.c-news-single__date {
  display: inline-block;
  font-size: 12.5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.c-news-single__video-caption,
.c-news-single__image-caption {
  font-size: 12.5px;
}
