.c-box-list {
  margin-bottom: 30px;

  &--patient_portal {
    margin: 30px 0;
  }
}

.c-box-list__inner {
  min-height: 265px;
  margin-top: 5px;
  padding-bottom: 10px;
  background-color: #fff;
  @include linear-gradient(#ffffff, #add8e3, 0%, 95%);
}

.c-box-list__content {
  padding: 10px 13px 0 16px;
  line-height: 1.6;
  font-family: $font-family-3;
}

.c-box-list__header {
  color: $color-white;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  background-color: #45acc6;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  @include linear-gradient(#45acc6, #297c92, 10%);
}

.c-box-list__list-item {
  padding-bottom: 3px !important;

  &--button {
    width: calc(100% + 13px);
    margin-top: 10px;
    margin-left: -13px;
    text-align: center;

    &:before {
      display: none;
    }
  }
}
