.c-embed {
  &--video {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 30px;
    margin-bottom: 10px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
