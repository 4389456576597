///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin bp-small($type: 'min') {
  @media (#{$type}-width: $bp-small) {
    @content;
  }
}

@mixin bp-medium-small($type: 'min') {
  @media (#{$type}-width: $bp-medium-small) {
    @content;
  }
}

@mixin bp-medium($type: 'min') {
  @media (#{$type}-width: $bp-medium) {
    @content;
  }
}

@mixin bp-large($type: 'min') {
  @media (#{$type}-width: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge-medium($type: 'min') {
  @media (#{$type}-width: $bp-xlarge-medium) {
    @content;
  }
}

@mixin bp-xlarge($type: 'min') {
  @media (#{$type}-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-xxlarge($type: 'min') {
  @media (#{$type}-width: $bp-xxlarge) {
    @content;
  }
}

@mixin bp-full($type: 'min') {
  @media (#{$type}-width: $bp-full) {
    @content;
  }
}

@mixin set-full-width() {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@mixin center_element($width:250px) {
  width: 100%;
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}
