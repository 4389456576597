/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  height: 0;
  box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
