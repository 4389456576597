.c-recent-news__heading {
  text-transform: uppercase;
  color: $color-green !important;
}

.c-recent-news__content {
  overflow: hidden;
}

.c-recent-news__image {
  float: right;
  max-width: 80px;
  margin-left: 15px;
}

.c-recent-news__more {
  margin-left: 12px;
  color: $color-dark-grey;

  &:hover {
    text-decoration: none;
    color: $color-orange;
  }

  &:before {
    content: '\00BB \0020';
    color: #f26522;
    margin-left: -12px;
  }
}
