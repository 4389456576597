.c-google-translate__dropdown {
  display: flex;
  padding: 0 30px 20px;
  justify-content: flex-end;
  align-items: center;
  font-family: $font-family-3;
  color: $color-blue;
}

.c-google-translate__element {
  padding-left: 8px;
}

.c-content--es {
  display: none;

  .c-body--lang-es & {
    display: block;
  }
}

.c-content--en {
  .c-body--lang-es & {
    display: none;
  }
}
