.c-location__image {
  max-width: 170px;
  border: 1px solid $color-light-grey;

  @include bp-large('max') {
    max-width: 215px;
    width: 100%;
  }
}

.c-location__hours-header {
  padding-top: 20px;
  padding-bottom: 9px;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.0625rem;
  color: $color-grey;
  text-transform: uppercase;
  border-bottom: 3px double $color-light-grey;
  font-family: $font-family-1;
}

.c-location__content {
  padding-top: 5px;
}

.c-location__hours {
  margin-bottom: 10px;
}

.c-location__hour-info {
  padding: 1% 5%;
  font-style: italic;
  text-align: center;
}

.c-location__map-header {
  font-family: $font-family-1;
  font-size: 1rem;
}

.c-location__payments-heading,
.c-location__services-heading {
  text-transform: uppercase;
}

.c-location__medical-providers-heading {
  color: $color-green;
}

.c-location__map-element {
  height: 200px;
}
