.rwd {
  display: none;
  opacity: 0;

  @include bp-xlarge('max') {
    &--1200 {
      opacity: 1;
    }
  }

  @include bp-large('max') {
    &--992 {
      opacity: 1;
    }
  }

  @include bp-medium('max') {
    &--768 {
      opacity: 1;
    }
  }
}
