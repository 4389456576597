/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

/* stylelint-disable */

.c-btn,
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type='submit'],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type='submit'],
.post-password-form input[type=submit] {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  font-size: 14px;
  line-height: 1.428571429;
  font-weight: 400;
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 6px 12px;
  transition: $global-transition;
  border-radius: $global-radius * 2;
  user-select: none;
  text-decoration: none !important;
  outline: none !important;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  padding-left: 25px;
  padding-right: 25px;
  background-color: $btn-color-light;
  @include linear-gradient($btn-color-light, $btn-color);
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.25);

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $btn-color;
    @include linear-gradient($btn-color, $btn-color-light);
  }
}

.c-btn--submit {
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 0;
  border: #536813 1px solid;
  @include linear-gradient(#6faf3d, #3e711b);
  box-shadow: inset 1px 2px 0 0 rgba(255, 255, 255, 0.35);

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: #3e711b;
    @include linear-gradient(#3e711b, #6faf3d);
  }
}

.c-btn--info,
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type='submit'],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type='submit'],
.post-password-form input[type=submit] {
  border: #46b8da 1px solid;
  background-color: #5bc0de;
  border-radius: 4px;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: #39b3d7;
    border-color: #269abc;
  }
}

.c-btn--submit-alt {
  display: block;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 0;
  border: #46b8da 1px solid;
  background-color: #5bc0de;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: #39b3d7;
    border-color: #269abc;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--full-width {
  width: 100%;
}
