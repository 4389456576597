.c-widget__title {
  font-family: $font-family-1;
  text-transform: uppercase;
  color: #2e859c;
}

.c-widget {
  ul {
    list-style-type: none;
    margin: 0 0 10px;
    padding-left: 13px;
    color: $color-dark-grey;
    font-family: $font-family-3;

    li {
      padding: 0 0 6px;

      &:before {
        color: $color-link-list;
      }
    }

    a:not(.c-btn) { // stylelint-disable-line
      color: $color-dark-grey;
    }
  }
}

.widget_nav_menu { // stylelint-disable-line
  ul {
    list-style-type: none;
    margin: 0 0 10px;
    padding-left: 0;
    color: $color-white;
    font-family: $font-family-1;

    li {
      font-size: 12px;
      line-height: 18px;
      padding: 0 0 8px;

      &:before {
        display: none;
      }
    }

    a:not(.c-btn) { // stylelint-disable-line
      color: $color-white;
    }

    a:hover {
      color: #7bdff9;
      text-decoration: none;
    }
  }
}
