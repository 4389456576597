/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav__navigation {
  @include linear-gradient(#45acc6, #297c92, 10%);

  @include bp-medium('max') {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.c-main-nav {
  display: flex;
  margin: 0;
  border-top: 2px solid $color-white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  @include linear-gradient(#45acc6, #297c92, 10%);

  @include bp-medium('max') {
    border-top: none;
    display: none;
    flex-direction: column;
    @include set-full-width();
  }
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp-medium() {
    border-right: 2px solid $color-white;
  }

  &:last-child {
    border-right: none;
  }

  &:hover > .c-main-nav__dropdown {
    @include bp-medium() {
      display: flex;
    }
  }

  &:hover,
  &.current-menu-parent,
  &.is-active {
    .c-main-nav__link {
      background: #70b23f;
      @include linear-gradient(#70b23f, #427e1f, 7%);
    }
  }
}

.c-main-nav__link {
  display: block;
  padding: 10px 36.5px;
  text-decoration: none !important;
  font-size: 1em;
  color: $color-white !important;
  text-transform: uppercase;

  .c-body--istranslated & {
    padding: 10px 29px;
  }

  @include bp-xlarge('max') {
    padding: 10px 31px;
  }

  @include bp-large('max') {
    padding: 10px 15px;
  }
}

.c-main-nav__item-text {
  @include bp-medium('max') {
    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid $color-white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      border-bottom: 0 dotted;
    }

    .c-main-nav__link:active &:after {
      border-top: 4px solid $color-hover;
    }
  }
}

.current-menu-item .c-main-nav__link {
  //border-bottom-color: $color-black;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
  min-width: 160px;
  background: $color-very-light-grey;
  border: 0 solid $color-very-light-grey;
  padding: 15px 0;
  border-radius: 0;

  .c-main-nav__subitem & {
    padding: 0;
    left: 100%;
    top: 0;
    max-width: 160px;

    @include bp-medium('max') {
      top: auto;
      left: auto;
    }
  }

  @include bp-medium('max') {
    position: relative;
    min-width: 1px;
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
}

.c-main-nav__subitem {
  position: relative;
  list-style: none;
  margin: 0;

  &:hover > .c-main-nav__dropdown {
    @include bp-medium() {
      display: flex;
    }
  }
}

.c-main-nav__subsubitem {
  position: relative;
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink,
.c-main-nav__subsublink {
  display: block;
  padding: 6px 20px;
  text-decoration: none !important;
  color: $color-grey-medium !important;

  .current-menu-item &,
  &:hover {
    background-color: #f59231;
    color: $color-white !important;
  }

  @include bp-medium('max') {
    padding: 5px 15px 5px 25px;
    color: $color-white !important;
  }
}

.c-main-nav__toggle {
  position: relative;
  margin: 5px 15px 5px 0;
  padding: 9px 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  display: none;
  cursor: pointer;

  @include bp-medium('max') {
    display: flex;
    align-content: center;
  }
}

.c-main-nav__toggle-text {
  color: $color-white;
  font-weight: bold;
  font-size: 1rem;
  padding-right: 10px;
  line-height: 1;
  position: relative;
}

.c-main-nav__toggle-icon-bar {
  background: $color-white;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.c-main-nav__toggle-icon-bar + .c-main-nav__toggle-icon-bar {
  margin-top: 4px;
}
