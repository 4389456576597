/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  max-width: 100%;
}

.o-wrapper__inner {
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;

  @include bp-xlarge('max') {
    max-width: $container-width-medium;
  }

  @include bp-large('max') {
    max-width: $container-width-small;
  }
}
