.c-press-post__header {
  align-items: flex-start;

  @include bp-medium('max') {
    flex-wrap: wrap;
  }
}

.c-press-post__title {
  margin-top: 0;
}

.c-press-post__image {
  min-width: 250px;
  text-align: right;

  @include bp-medium('max') {
    padding-bottom: 5px;
    text-align: center;
    order: -1;
  }
}

.c-press-post__image,
.c-press-post__meta {
  @include bp-medium('max') {
    min-width: 1px;
    @include set-full-width();
  }
}


.c-press-post__content {
  padding-top: 10px;
}

.c-press__contact {
  margin-bottom: 30px;
}

.c-press__contact-heading {
  margin: 0 0 15px;
  padding-top: 20px;
  padding-bottom: 9px;
  font-size: 1.0625rem;
  text-transform: uppercase;
  border-bottom: 3px double $color-light-grey;
  font-family: $font-family-1;
  color: $color-grey;
}

.c-press__contact-content {
  strong {
    color: $color-green;
  }
}
