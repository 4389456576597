/* stylelint-disable declaration-block-no-duplicate-properties */

@mixin horizontal-gradient($from, $to) {
  background: $to;
  background: -moz-linear-gradient(left, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop(100%, $to));
  background: -webkit-linear-gradient(left, $from 0%, $to 100%);
  background: -o-linear-gradient(left, $from 0%, $to 100%);
  background: linear-gradient(to right, $from 0%, $to 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#{$from}, endColorstr=#{$to}, GradientType=1);
}

@mixin linear-gradient($from, $to, $from_percent: 0%, $to_percent: 100%) {
  background: $to;
  background: -moz-linear-gradient(top, $from $from_percent, $to $to_percent);
  background: -webkit-gradient(linear, left top, left bottom, color-stop($from_percent, $from), color-stop($to_percent, $to));
  background: -webkit-linear-gradient(top, $from $from_percent, $to $to_percent);
  background: -o-linear-gradient(top, $from $from_percent, $to $to_percent);
  background: linear-gradient(to bottom, $from $from_percent, $to $to_percent);
  filter: progid:dximagetransform.microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

/* stylelint-enable */
