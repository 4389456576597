.c-service__services-heading,
.c-service__more-about-heading {
  color: $color-green;
}

.c-service__payments-heading,
.c-service__locations-heading {
  text-transform: uppercase;
}

.c-service__locations {
  margin-bottom: 10px;
}

.c-service__extra-content {
  padding-top: 20px;
}
