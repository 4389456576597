/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */
$columns_no: 12;

.o-layout {
  width: 100%;

  display: flex;
  flex-flow: row wrap;

  &--auto {
    width: auto;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--gutless {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.o-layout__item {
  flex: 1;
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;

  &--gutless {
    padding: 0;
  }

  &--auto {
    width: auto;
    flex-basis: auto;
  }

  @for $i from 1 through $columns_no {
    &--#{$i} {
      width: ((100 / $columns_no) * $i) * 1%;
      max-width: ((100 / $columns_no) * $i) * 1%;
      flex-basis: ((100 / $columns_no) * $i) * 1%;
    }
  }

  &--20 {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }

  @include bp-medium() {
    @for $i from 1 through $columns_no {
      &--small-#{$i} {
        width: ((100 / $columns_no) * $i) * 1%;
        max-width: ((100 / $columns_no) * $i) * 1%;
        flex-basis: ((100 / $columns_no) * $i) * 1%;
      }
    }

    &--small-20 {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  @include bp-large() {
    @for $i from 1 through $columns_no {
      &--medium-#{$i} {
        width: ((100 / $columns_no) * $i) * 1%;
        max-width: ((100 / $columns_no) * $i) * 1%;
        flex-basis: ((100 / $columns_no) * $i) * 1%;
      }
    }

    &--medium-20 {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  @include bp-xlarge() {
    @for $i from 1 through $columns_no {
      &--large-#{$i} {
        width: ((100 / $columns_no) * $i) * 1%;
        max-width: ((100 / $columns_no) * $i) * 1%;
        flex-basis: ((100 / $columns_no) * $i) * 1%;
      }
    }

    &--large-20 {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }
  }
}

.o-layout--guttered {
  justify-content: space-between;

  > .o-layout__item {
    //flex: none;
  }
}
