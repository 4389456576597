/* stylelint-disable */

.c-form__group {
  margin-bottom: 10px;
}

.c-form__control,
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper textarea,
.post-password-form input:not([type=submit]) {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: $color-darker-grey;
  background-color: $color-white;
  background-image: none;
  border: 1px solid $color-medium-grey;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  &:-moz-placeholder {
    color: #999999;
  }

  &::-moz-placeholder {
    color: #999999;
  }

  &:-ms-input-placeholder {
    color: #999999;
  }

  &::-webkit-input-placeholder {
    color: #999999;
  }
}

label {
  color: $color-grey;
}

.gform_wrapper {
  textarea {
    &.medium {
      height: 100px !important;
    }
  }

  .gform_footer {
    margin: 0 !important;
  }

  ul.gform_fields li.gfield {
    padding: 0 !important;
    margin-top: 15px !important;
  }

  .validation_error {
    padding: 5px 0 !important;
    margin: 0 !important;
    background-color: $color-white !important;
    color: $form-error-color !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
  }

  &.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    min-width: 100% !important;
  }

  .gfield_error {
    background-color: transparent !important;
    min-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }

  li.gfield.gfield_error.gfield_contains_required div.ginput_container,
  li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin: 0 !important;
  }

  li.gfield.gfield_error.gfield_contains_required div.gfield_description {
    padding: 0 !important;
    color: $form-error-color !important;
  }

  .gfield_error .gfield_label {
    color: $color-grey !important;
  }

  li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  li.gfield_error textarea {
    border: 1px solid $color-medium-grey !important;
  }
}

.c-form--inline {
  @include bp-medium() {
    padding-right: 30px;
  }

  .gfield {
    @extend .o-layout;
  }

  .gfield_label {
    text-align: right;
    @extend .o-layout__item;
    @extend .o-layout__item--small-3;

    @include bp-medium('max') {
      text-align: left;
      padding: 0;
    }
  }

  .ginput_container {
    @extend .o-layout__item;
    @extend .o-layout__item--small-9;
    padding-right: 15px !important;

    @include bp-medium('max') {
      padding: 0 !important;
    }
  }

  .gfield_description {
    @include bp-medium() {
      margin-left: calc(25% + 15px) !important;
      width: calc(75% - 15px) !important;
    }
  }

  .gform_footer {
    @include bp-medium() {
      padding-left: calc(25% + 15px) !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0 1000px #fff inset !important;
}
