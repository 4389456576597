/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer__boxes {
  padding: 10px 0 10px;
  background-color: $color-bg-light;
  border-top: 1px solid #e9e8e8;
  align-items: flex-start;
  justify-content: center;
}

.c-footer__box {
  max-width: 273px;
  text-align: left;
  margin: 1.5%;
  border: 4px solid $color-white;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.25);

  @include bp-medium-small() {
    &--break {
      display: none;
    }
  }
}

.c-footer__box-inner {
  padding-right: 10px;
  align-items: flex-start;
  background-color: $color-bg-light;
  color: inherit;
  @include linear-gradient($color-bg-light, #dadcdf , 50%);

  &:hover {
    text-decoration: none;
    color: inherit;
    @include linear-gradient($color-white, #edf8ff , 30%);
  }
}

.c-footer__box-image {
  margin-right: 10px;
}

.c-footer__box-title {
  padding-top: 4px;
  padding-bottom: 2px;
  font-weight: 400;
  margin: 0;
  font-size: 1.3125rem;
}

.c-footer__box-subtitle {
  margin: 0;
  color: $color-green;
  font-size: 13px;
  text-transform: uppercase;
  font-family: $font-family-1;
}

.c-footer__box-text {
  margin: 0;
  padding-top: 4px;
  line-height: 1.3;
  font-size: 12px;
}

.c-footer__newlsetter {
  margin-top: 15px;
  padding-top: 0;
  padding-bottom: 15px;
  text-align: center;
  color: #898989;
  background: transparent;

  @include bp-medium-small('max') {
    padding-bottom: 0;

    .c-form__group {
      margin-bottom: 5px;
    }
  }

  .c-form__control {
    width: 250px;

    @include bp-medium-small('max') {
      margin-left: auto;
      margin-right: auto;
    }
  }

  form {
    align-items: center;
    justify-content: center;

    @include bp-medium-small('max') {
      display: block;
    }
  }

  label {
    margin-right: 5px;
  }

  .c-btn {
    margin-left: 10px;
  }
}

.c-footer__bottom {
  padding: 5px 30px;
  background: #287b91;

  * {
    color: $color-white;
  }

  .c-widget__title {
    padding-bottom: 10px;
    color: $color-hover-light;
  }

  a:hover {
    color: $color-hover-light;
    text-decoration: none;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 20px;
    border-top: 1px solid #7bdff9;
  }
}

.c-footer__bottom-content p:last-of-type {
  margin-bottom: 0;
}

.c-footer__bottom-credits {
  ul {
    padding: 0;
    margin: 0 0 10px;
    list-style-type: none;
  }

  li {
    display: inline-block;
    padding-right: 10px;
    font-size: 11px;
  }
}
