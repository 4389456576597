.c-upcoming-events {
  margin-bottom: 40px;
}

.c-upcoming-events__heading {
  text-transform: uppercase;
  color: $color-green !important;
}

.c-upcoming-events__item {
  margin-bottom: 30px;
}

.c-upcoming-event__more-link {
  margin-left: 30px;
  color: $color-dark-grey;

  &:hover {
    text-decoration: none;
    color: $color-orange;
  }

  &:before {
    content: '\00BB \0020';
    color: #f26522;
    margin-left: -12px;
  }
}

.c-upcoming-event__button {
  text-transform: uppercase;
  text-align: right;
}
