/* ==========================================================================
   #LISTS
   ========================================================================== */

.c-page__container {
  ul {
    list-style-type: none;
    margin: 0 0 10px;
    padding-left: 13px;
    color: $color-grey;

    li {
      padding: 0;

      &:before {
        content: '\00BB \0020';
        color: $color-link;
        margin-left: -12px;
      }
    }

    a:not(.c-btn) {
      &:hover {
        color: $color-link-list;
        text-decoration: none;
      }
    }
  }
}

ul {
  .c-home__box-content &,
  &.u-list-color { // stylelint-disable-line
    font-family: $font-family-3;

    li {
      padding: 0 0 6px;

      &:before {
        color: $color-link-list;
      }
    }

    a:not(.c-btn) { // stylelint-disable-line
      color: $color-dark-grey;
    }
  }
}

li {
  list-style-position: inside;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
