.c-home-slider {
  .flexslider {
    margin: 0;
    padding: 4px 2px 2px;
    border: none;
  }
}

.c-home__box-inner {
  margin-top: 15px;
  padding-bottom: 10px;
  background-color: #fff;
  @include linear-gradient(#ffffff, #add8e3, 0%, 95%);

  @include bp-medium() {
    min-height: 265px;
  }
}

.c-home__content {
  h2 {
    margin-top: 0;
  }

  &--right {
    @include bp-large('max') {
      @include center_element(250px);
    }
  }
}

.c-home__box-content {
  padding: 10px 13px 0 16px;
  line-height: 1.6;
  font-family: $font-family-3;
}

.c-home__box-header {
  color: $color-white;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background-color: #70b23f;
  @include linear-gradient(#70b23f, #427e1f, 7%);
}

.c-home__bottom-content {
  padding: 20px 0 10px;
  margin-top: 10px;
  border-top: 3px double $color-light-grey;
}
