.c-events-page__title {
  .o-layout {
    justify-content: space-around;
    align-items: flex-start;
  }

  .c-btn {
    font-family: $font-family-1;
    flex: 0 0 auto;
    font-style: normal;
  }
}

.c-event__left {
  .c-btn {
    display: block;
    margin: 0 auto;
    max-width: 105px;
  }
}

.c-events__list-item {
  min-height: 265px;
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: $color-white;
  @include linear-gradient($color-white, #bfe1e9);
}

.c-events__list-header {
  margin: 0 0 10px;
  padding: 7px 3px;
  color: $color-white;
  background: #70b23f;
  @include linear-gradient(#70b23f, #427e1f, 7%);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  font-size: 1.4375rem;
  text-align: center;
}

.c-event {
  padding: 10px 30px 20px;
}

.c-event__date-box {
  text-align: center;
  padding: 13px 4px 17px;
  margin: 0 10px 30px;
  background: #2e859c;
  font-size: 1.25rem;
  line-height: 1;
  color: $color-white;
  border-radius: 10px;
  font-family: $font-family-2;

  @include bp-medium('max') {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-event__day-no {
  display: block;
  font-size: 2.625rem;
}

.c-event__title {
  margin-top: 5px;
  font-size: 1.25rem;
  color: $color-blue;
}

.c-event__content {
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px;
    line-height: 1.4;
    color: $color-grey;
  }

  h2 {
    font-size: 1.3125rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.8125rem;
  }
}

.c-event__date {
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 10px;
  color: $color-blue;
  font-family: $font-family-3;
}

.c-event__info-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;

  strong {
    display: block;
    min-width: 114px;
    max-width: 114px;
  }
}

.c-events__past-link {
  text-align: right;
}
