.gallery {
  @extend .o-layout;

  &-item {
    padding: 5px;

    img {
      max-width: 100%;
      height: auto;
      max-height: 100%;
      width: auto;
    }
  }

  &-icon {
    width: 100%;
  }
}

@for $i from 1 through 9 {
  .gallery-columns-#{$i} {
    .gallery-item {
      width: (100 / $i) * 1%;
      max-width: (100 / $i) * 1%;
      flex-basis: (100 / $i) * 1%;

      @include bp-xlarge('max') {
        @if $i > 6 {
          width: (100 / 6) * 1%;
          max-width: (100 / 6) * 1%;
          flex-basis: (100 / 6) * 1%;
        }
      }

      @include bp-xlarge-medium('max') {
        @if $i > 5 {
          width: (100 / 4) * 1%;
          max-width: (100 / 4) * 1%;
          flex-basis: (100 / 4) * 1%;
        }
      }

      @include bp-large('max') {
        @if $i > 5 {
          width: (100 / 5) * 1%;
          max-width: (100 / 5) * 1%;
          flex-basis: (100 / 5) * 1%;
        }
      }

      @include bp-medium('max') {
        @if $i > 4 {
          width: (100 / 4) * 1%;
          max-width: (100 / 4) * 1%;
          flex-basis: (100 / 4) * 1%;
        }
      }

      @include bp-medium-small('max') {
        @if $i > 3 {
          width: (100 / 3) * 1%;
          max-width: (100 / 3) * 1%;
          flex-basis: (100 / 3) * 1%;
        }
      }

      @include bp-small('max') {
        @if $i > 2 {
          text-align: center;
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}
