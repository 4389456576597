.c-content-box {
  margin-top: 5px;
  padding-bottom: 10px;
  background-color: #fff;
  @include linear-gradient(#ffffff, #add8e3, 0%, 95%);
}

.c-content-box__content {
  padding: 20px 30px;
  line-height: 1.6;
}

.c-content-box__heading {
  color: $color-white;
  text-align: center;
  margin: 0;
  font-weight: normal;
  padding: 7px 3px;
  font-size: 1.4375rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
  background-color: #70b23f;
  @include linear-gradient(#70b23f, #427e1f, 7%);
}
