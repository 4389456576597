.c-vision {
  margin-bottom: 40px;
}

.c-vision__heading {
  margin: 0 0 15px;
  padding-top: 0;
  padding-bottom: 9px;
  font-size: 1.0625rem;
  text-transform: uppercase;
  border-bottom: 3px double $color-light-grey;
  font-family: $font-family-1;
  color: $color-grey;
}

.c-vision__item-heading {
  color: $color-blue;
  font-family: $font-family-3;
}
